define("granite/templates/account/employee/index/counseling/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6i5P/1k6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui raised padded segment container clearfix animated fadeInUp\"],[8],[0,\"\\n  \"],[7,\"hgroup\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n      Edit Employment Issue\\n      \"],[5,\"link-to\",[[12,\"class\",\"right floated text\"]],[[\"@route\",\"@model\"],[\"account.employee.index.counseling.issue.index\",[23,0,[\"model\",\"id\"]]]],{\"statements\":[[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"remove icon\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n  \"],[5,\"quick-form\",[],[[\"@form\",\"@model\",\"@controller\",\"@tagName\"],[[23,0,[\"form\"]],[23,0,[\"model\"]],[23,0,[]],\"div\"]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n  \"],[7,\"form\",false],[3,\"on\",[\"submit\",[28,\"prevent-default\",[[28,\"fn\",[[23,0,[\"data\",\"saveRecord\"]],[23,0,[\"model\"]],\"working\",[23,0,[\"saveOptions\"]]],null]],null]]],[8],[0,\"\\n    \"],[5,\"form/action-button\",[[12,\"class\",\"ui positive fluid huge button\"],[12,\"type\",\"submit\"]],[[\"@loading\"],[[23,0,[\"data\",\"statuses\",\"working\",\"isLoading\"]]]],{\"statements\":[[0,\"\\n      Save Issue\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/employee/index/counseling/edit.hbs"
    }
  });

  _exports.default = _default;
});